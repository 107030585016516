import React from 'react';

const Logo = () => (
  <p className="logo">
    Studio
    <br />
    basilic
    <br />
    tropical
  </p>
);

export default Logo;
